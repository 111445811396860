<template>
  <div
    class="card has-border-radius p-4 h-full column is-12 shadow-none card-requisito border-2 flex items-center"
    :class="{
      'has-background-primary-04 has-border-primary':
        requisito.id == selected_id,
      'has-background-white-ter has-border-white-ter':
        requisito.id != selected_id,
      'has-background-grey-lighter': draggable
    }"
  >
    <div class="columns is-multiline">
      <div class="column" :class="{ 'is-12 pb-0': kanban, 'is-2': !kanban }">
        <b-icon
          :icon="iconProperties(requisito.status).icon"
          :pack="iconProperties(requisito.status).pack"
          :size="iconProperties(requisito.status).size"
          :class="iconProperties(requisito.status).class"
        />
      </div>
      <div class="column text-left">
        <p
          class="font-medium has-text-grey-darker"
          :class="{ 'text-lg': !kanban, 'text-md': kanban }"
        >
          {{ requisito.requisito.nombre }}
        </p>
        <p class="has-text-grey-light" v-if="!kanban">
          {{ requisito.files.length }} Documentos
        </p>
        <p
          class="has-text-primary underline text-lg mt-4 cursor-pointer"
          @click="selectRequisito(requisito)"
        >
          Ver detalles
          <fa-icon icon="chevron-right" size="1x" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RequisitoCard",
  props: {
    requisito: Object,
    selected_id: [Number, null],
    kanban: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectRequisito(requisito) {
      this.$emit("select", requisito);
    },
    //Indica colores y cosas custom dependiendo del status
    iconProperties(status) {
      if (status == null || status.id == null) {
        return {
          icon: "circle",
          pack: "far",
          size: "is-medium",
          color: "has-text-grey"
        };
      }
      switch (status.nombre) {
        case "Correcto":
        case "Aceptado":
        case "Reportado a organo supervisor":
          return {
            icon: "check-circle",
            size: "is-medium",
            pack: "fas",
            class: "has-text-success",
            color: "has-text-success"
          };
        case "Seguimiento":
          return {
            icon: "sync",
            size: "is-small",
            pack: "fas",
            class:
              "p-4 has-background-warning rounded-full has-text-grey-darker",
            color: "has-text-warning"
          };
        case "Negativo":
        case "Rechazado":
          return {
            icon: "times-circle",
            class: "has-text-danger",
            pack: "fas",
            size: "is-medium",
            color: "has-text-danger"
          };
        case "En revisión":
          return {
            icon: "info-circle",
            class: "has-text-info",
            pack: "fas",
            size: "is-medium",
            color: "has-text-info"
          };
        case "Modificado":
          return {
            icon: "exclamation-circle",
            class: "has-text-warning",
            pack: "fas",
            size: "is-medium",
            color: "has-text-warning"
          };
        default:
          return {
            icon: "circle",
            pack: "far",
            size: "is-medium",
            color: "has-text-grey"
          };
      }
    }
  }
};
</script>
