<template>
  <div class="columns overflow-x-auto overflow-y-auto ">
    <div class="column">
      <div
        class="card h-full has-background-white-ter has-border-radius p-3 text-left shadow-none kanban__column"
      >
        <p class="font-bold text-left">Sin estatus</p>
        <div class="overflow-y-auto h-full max-h-40rem md:h-screen">
          <p
            class="absolute center-y center-x mt-16 font-medium has-text-grey text-center"
            v-if="
              innerLists['list_null'].length == 0 ||
                innerLists['list_null'] == null
            "
          >
            No hay requisitos
          </p>
          <draggable
            :list="innerLists['list_null']"
            :group="{ name: 'requisito', put: false }"
            id="list_null"
            @end="moveToList"
            class="list-group kanban-column"
          >
            <requisito-card
              :kanban="true"
              @select="req => $emit('show', req)"
              :requisito="requisito"
              v-for="requisito in innerLists['list_null']"
              :key="requisito.id"
              :id="'req' + requisito.id"
              :draggable="true"
            />
          </draggable>
        </div>
      </div>
    </div>
    <div class="column" v-for="status in statuses" :key="status.id">
      <div
        class="card has-background-white-ter has-border-radius p-3 text-left shadow-none h-full kanban__column"
      >
        <p class="font-bold text-left">{{ status.nombre }}</p>
        <div class="overflow-y-auto h-full max-h-40rem">
          <p
            class="absolute center-y center-x mt-16 font-medium has-text-grey text-center"
            v-if="innerLists['list_' + status.id].length == 0"
          >
            No hay requisitos
          </p>
          <draggable
            :list="innerLists['list_' + status.id]"
            :group="{ name: 'requisito' }"
            :id="'list_' + status.id"
            @end="moveToList"
            class="list-group kanban-column"
          >
            <requisito-card
              :kanban="true"
              @select="req => $emit('show', req)"
              :requisito="requisito"
              v-for="requisito in innerLists['list_' + status.id]"
              :key="requisito.id"
              :id="'req' + requisito.id"
              :draggable="true"
            />
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import RequisitoCard from "@/components/cards/RequisitoCard";

export default {
  name: "Kanban",
  data() {
    return {
      innerLists: null
    };
  },
  components: {
    draggable,
    RequisitoCard
  },
  props: {
    lists: Object,
    statuses: Array
  },
  methods: {
    moveToList(data) {
      if (data.from.id === data.to.id) {
        return;
      }
      let list_from = {
        name: data.from.id,
        status: this.statuses.find(x => x.id == data.from.id.split("list_")[1])
      };
      let list_to = {
        name: data.to.id,
        status: this.statuses.find(x => x.id == data.to.id.split("list_")[1])
      };
      this.$buefy.dialog.prompt({
        title: "Cambiar status del requisito",
        message: `¿Estas seguro que deseas cambiar el status del requisito de <b>${
          list_from.status ? list_from.status.nombre : "Sin estatus"
        }</b> a <b>${list_to.status.nombre}</b>? ¿Cual es el motivo?`,
        inputAttrs: {
          placeholder: "Datos completos...",
          maxlength: 255
        },
        canCancel: ["button"],
        confirmText: "Cambiar status",
        cancelText: "Cancelar",
        type: "is-primary",
        onCancel: () => {
          return this.revertDrop(
            list_from.name,
            list_to.name,
            data.item.id.split("req")[1]
          );
        },
        onConfirm: motivo => {
          this.$emit("change-status", {
            id: data.item.id.split("req")[1],
            status: list_to.status.id,
            motivo: motivo
          });
        }
      });
    },
    revertDrop(from, to, id) {
      let item = this.lists[to].find(req => req.id == id);
      this.lists[from].unshift(item);
      this.lists[to].splice(
        this.lists[to].findIndex(req => req.id == id),
        1
      );
      return true;
    }
  },
  watch: {
    lists(newVal) {
      this.innerLists = newVal;
    }
  },
  created() {
    if (this.lists) {
      this.innerLists = this.lists;
    }
  }
};
</script>

<style lang="scss">
.empty-bg {
  background-image: url("/images/requisito_placeholder.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  padding-bottom: 60px;
}
.kanban-column {
  min-height: 300px;
}
.kanban__column {
  min-width: 250px;
}
</style>
