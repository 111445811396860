<template>
  <div class="modal-card" style="width: auto">
    <section
      class="modal-card-body has-background-white text-left has-border-radius"
    >
      <button
        type="button"
        class="delete absolute right-0 mr-5"
        @click="$parent.close()"
      />
      <p class="text-3xl font-bold">
        {{ requisito.requisito.nombre }}
      </p>
      <p class="has-text-grey-light font-light text-lg mt-4">Descripción</p>
      <p>{{ requisito.requisito.descripcion }}</p>
      <div class="columns">
        <div class="column is-8">
          <p class="has-text-grey-light font-light text-lg mt-3">Comentarios</p>
          <comentarios
            class="pt-3"
            :comentarios="requisito.comments"
            :key="reload_comments"
            :no-bg="true"
            @comentar="enviarComentario"
            tipo="requisito"
          />
        </div>
        <div class="column is-4">
          <sidebar
            :requisito="requisito"
            :hideComments="true"
            :hideDescription="true"
            :hideTitle="true"
            :all-status="allStatus"
            @cambiar-status="data => $emit('cambiar-status', data)"
            @toggle-cf="data => $emit('toggle-cf', data)"
            @desligar-doc="data => $emit('desligar-doc', data)"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Comentarios from "@/components/generals/Comentarios";
import Sidebar from "@/components/generals/SidebarRequisitos";

export default {
  name: "ModalRequisitos",
  props: {
    requisito: Object,
    allStatus: Array
  },
  data() {
    return {
      reload_comments: 0
    };
  },
  methods: {
    enviarComentario(comentario) {
      comentario.requisito_id = this.requisito.id;
      this.$emit("enviar-comentario", comentario);
    }
  },
  watch: {
    requisito() {
      this.reload_comments++;
    }
  },
  components: {
    Comentarios,
    Sidebar
  }
};
</script>
